export default function () {
  const input = document.querySelector("#images-input input");
  const imagesPreviewContainer = document.querySelector("#images-input label");
  const images = new Set();

  if(!input) return;

  const updateImages = (files) => {
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (!images.has(e.target.result)) {
          images.add(e.target.result);
          updatePreview();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const updatePreview = () => {
    imagesPreviewContainer.innerHTML = "";
    images.forEach(src => {
      const div = document.createElement("div");
      div.style.width = "48px";
      div.style.height = "48px";
      div.style.backgroundImage = `url('${src}')`;
      div.style.backgroundSize = "cover";
      div.style.backgroundPosition = "center";
      imagesPreviewContainer.appendChild(div);
    });
  }

  input.addEventListener("change", (e) => {
    const files = e.target.files;
    updateImages(files);
  });
}
