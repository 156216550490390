export default function () {
  const ratingBullets = document.querySelectorAll(".rating-bullets");

  if (ratingBullets.length > 0) {
    ratingBullets.forEach(review => {
      const bullets = review.querySelectorAll("span");
      const rating = review.dataset.rating;
      const color = review.dataset.color ?? "#0057B8";
      const ratingDecimalPercentage = (rating - Math.floor(rating)) * 100;

      bullets.forEach((bullet, i) => {
        if (i+1 <= rating) {
          bullet.style.backgroundColor = color;
        } else if(i+1 <= Math.ceil(rating)) {
          bullet.style.background = `linear-gradient(90deg, ${color} 0%, ${color} ${ratingDecimalPercentage}%, #F2F2F2 ${ratingDecimalPercentage}%, #F2F2F2 100%)`;
        }
      });
    });
  }
}
