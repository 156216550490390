import ratingBullets from '../utils/rating-bullets.js';

export default (productId) => ({
  rating: null,
  roundedRating: null,
  ratingCount: null,
  ratingCountScore1: null,
  ratingCountScore2: null,
  ratingCountScore3: null,
  ratingCountScore4: null,
  ratingCountScore5: null,
  loading: false,
  init() {
    this.fetchRatings();
  },
  async fetchRatings () {
    this.loading = true;
    const lang = document.documentElement.lang.toLowerCase();
    const ratingsSource = await fetch(`/${lang}/api/reviews/${productId}/ratings`);
    const ratingsData = await ratingsSource.json();
    this.loading = false;
    this.rating = ratingsData.data.map(r => r.rating).reduce((a, b) => a + b) / ratingsData.data.length;
    this.ratingCount = ratingsData.data.length;
    for (let index = 1; index <= 5; index++) {
      this["ratingCountScore" + index] = ratingsData.data.filter(r => r.rating === index).length;
    }
    this.roundedRating = Math.round(this.rating*10)/10;
    setTimeout(() => {
      ratingBullets();
    })
  },
});
