import ratingBullets from '../utils/rating-bullets.js';

export default (productId) => ({
  reviews: [],
  showForm: false,
  currentScore: 0,
  pagination: null,
  currentPage: 1,
  sort: "postDate desc",
  rating: null,
  roundedRating: null,
  loadingReviews: false,
  loadingRatings: false,
  ratingFilter: null,
  init () {
    this.fetchReviews();
    this.$watch('sort', () => {
      this.currentPage = 1;
      this.fetchReviews();
    });
    this.$watch('ratingFilter', () => {
      this.currentPage = 1;
      this.fetchReviews();
    });
  },
  submitForm () {
    fetch(this.$refs.reviewForm.getAttribute('action'), {
      method: this.$refs.reviewForm.getAttribute('method'),
      headers: {
        "Accept": "application/json",
      },
      body: new FormData(this.$refs.reviewForm)
    }).then(res => res.json())
      .then(function (data) {
        if (data.errors) {
          const msgError = document.querySelector("#msg-error");
          if(msgError) {
            msgError.classList.remove("hidden");
          }
        } else {
          const msgSuccess = document.querySelector("#msg-success");
          if(msgSuccess) {
            msgSuccess.classList.remove("hidden");
            document.querySelector("#write-review").classList.add("hidden");
            document.getElementById("review-form-container").scrollIntoView({ behavior: "smooth" });
          }
        }
      });
  },
  loadMore () {
    this.currentPage++;
    this.fetchReviews();
  },
  async fetchReviews () {
    this.loadingReviews = true;
    const lang = document.documentElement.lang.toLowerCase();
    const reviewsSource = await fetch(`/${lang}/api/reviews/${productId}?orderBy=${this.sort}&page=${this.currentPage}&rating=${this.ratingFilter ?? ''}`);
    const reviewsData = await reviewsSource.json();
    this.loadingReviews = false;
    this.pagination = reviewsData.meta.pagination;
    this.currentPage = this.pagination.current_page;
    if (this.currentPage > 1) {
      this.reviews = this.reviews.concat(reviewsData.data)
    } else {
      this.reviews = reviewsData.data;
    }
    this.$nextTick(() => {
      this.imagesPopup();
      ratingBullets();
    });
  },
  ratingFilterClick (rating) {
    if(rating === this.ratingFilter) {
      this.ratingFilter = null;
    } else {
      this.ratingFilter = rating;
    }
  },
  imagesPopup () {
    const imageButtons = document.querySelectorAll(".review-enlarge-img-btn");
    const imagePopup = document.querySelector("#review-img-popup");
    const enlargedImage = imagePopup.querySelector("img");
    const btnClosePopup = document.querySelector("#btn-close-img-popup");
    const popupBg =  document.querySelector("#review-img-popup-bg");
    let activeButton;

    if(imageButtons.length > 0 && imagePopup) {
      const closePopup = () => {
        imagePopup.classList.add("hidden");
        activeButton.focus();
        enlargedImage.src = "";
      };

      const openPopup = (btn) => {
        imagePopup.classList.remove("hidden");
        let imgSrc = btn.dataset.src;
        enlargedImage.src = imgSrc;
        btnClosePopup.focus();
      };

      const focusTrap = () => {
        const focusableElements = 'button:not(.hidden), [href]:not(.hidden), input:not(.hidden), select:not(.hidden), textarea:not(.hidden), [tabindex]:not([tabindex="-1"])';
        const focusableContent = imagePopup.querySelectorAll(focusableElements);
        const firstFocusableElement = focusableContent[0];
        const lastFocusableElement = focusableContent[focusableContent.length - 1];

        document.addEventListener('keydown', (e) => {
          if (e.key === "Escape") {
            closePopup();
          }

          if (e.key === 'Tab') {
            if (e.shiftKey) {
              if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                e.preventDefault();
              }
            } else {
              if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                e.preventDefault();
              }
            }
          }
        });
      }

      imageButtons.forEach(button => {
        button.addEventListener("click", () => {
          activeButton = button;
          openPopup(button);
          focusTrap();
        });
      });

      btnClosePopup.addEventListener("click", () => {
        closePopup();
      });

      popupBg.addEventListener("click", () => {
        closePopup();
      });
    }
  }
});
