export default function () {
  const selectVehicleBtns = document.querySelectorAll(".btn-select-vehicle-ga");
  const findDealerBtns = document.querySelectorAll(".btn-find-dealer-ga");

  if(selectVehicleBtns.length > 0) {
    selectVehicleBtns.forEach(btn => {
      btn.addEventListener("click", () => {
        window.dataLayer.push({
          event: "select_vehicle",
          product: btn.dataset.productName,
        });
      });
    });
  }

  if(findDealerBtns.length > 0) {
    findDealerBtns.forEach(btn => {
      btn.addEventListener("click", () => {
        window.dataLayer.push({
          event: "find_dealer",
          product: btn.dataset.productName,
        });
        console.log(window.dataLayer);
      });
    });
  }
}
