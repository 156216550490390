import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function () {
  const textOpacityContainer = document.querySelector('#text-opacity-container');
  if(!textOpacityContainer) return;

  const textDiv = document.querySelector('#text-opacity');
  const chars = textDiv.dataset.text.split("");

  chars.forEach(char => {
    let span = document.createElement('span');
    span.innerText = char;
    textDiv.appendChild(span);
  });

  gsap.fromTo("#text-opacity span", {
    opacity: 0.3
  },
  {
    opacity: 1,
    stagger: 0.08,
    scrollTrigger: {
      trigger: textOpacityContainer,
      start: "top 50%",
      toggleActions: "play none none none",
    }
  });
}
