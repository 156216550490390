export default () => ({
  open: false,
  panel: '',
  headerHeight: 0,
  init() {
    this.$watch('open', () => {
      document.body.classList.toggle('overflow-clip', this.open);
      document.documentElement.classList.toggle('overflow-clip');
      document.querySelector('.site-content').classList.toggle('overflow-clip');

      if (!this.open) {
        this.panel = '';
      }
    });

    this.calculateHeaderHeight();
  },
  calculateHeaderHeight() {
    this.headerHeight = document.querySelector('header').offsetHeight;
  },
  toggleMenu() {
    return () => {
      this.open = !this.open;
      this.calculateHeaderHeight();
    };
  },
  openPanel(id) {
    return () => {
      this.panel = id;
    };
  },
  back() {
    return () => {
      this.panel = '';
    };
  },
  openSearch() {
    return async () => {
      this.panel = 'searchMobile';

      await this.$nextTick();
      document.getElementById('header-mobile-searchform-input').focus();
    }
  },
  closeSearch() {
    return () => {
      this.panel = '';
    };
  },
  getDynamicStyle(returnTopOnly = false) {
    const top = this.headerHeight;

    if(returnTopOnly) {
      return {
        top: `${top}px`,
      }
    }
    return {
      top: `${top}px`,
      height: `calc(100vh - ${top}px)`,
    };
  }
});
