import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function () {
  const expendableImg = document.querySelector('#img-expandable');
  if(!expendableImg) return;

  let mm = gsap.matchMedia();

  mm.add("(max-width: 1023px)", () => {
    gsap.to('#img-translate-container', {
      xPercent: -80,
      scrollTrigger: {
        trigger: "#img-translate-container",
        start: "top 80%",
        scrub: true,
      }
    });
  });

  mm.add("(min-width: 1024px)", () => {
    const overlay = gsap.fromTo("#img-expandable-overlay", {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.7,
        ease: "none",
        scrollTrigger: {
          trigger: "#img-expandable-container",
          start: "top 10%",
          toggleActions: "play none none reverse",
        }
    });

    const tl = gsap.timeline({
      ease: "none",
    });

    tl.to(expendableImg, {
      width: "100vw",
      left: 0,
      transformOrigin: "bottom center",
    })
    .to(".img-expandable-before", {
      left: "-21vw",
    }, "<")
    .to(".img-expandable-after-1", {
      left: "101vw",
    }, "<")
    .to(".img-expandable-after-2", {
      left: "122vw",
    }, "<")

    ScrollTrigger.create({
      trigger: "#img-expandable-container",
      start: "top 80%",
      end: "top top",
      animation: tl,
      scrub: true,
    });
  });
}
