<template>
  <Teleport to="body">
    <transition name="fade">
      <div v-if="showModal"
           ref="modal"
           role="dialog"
           aria-modal="true"
           :aria-labelledby="ariaLabelledBy"
           class="fixed left-0 top-0 w-full h-dvh z-[1000]"
           :class="cssClass">
        <div class="absolute left-0 top-0 w-full h-dvh z-[10] bg-black/50"
             @click="showModal = false"></div>
        <div class="panel-content-container absolute right-0 top-0 w-full h-dvh max-w-[30.625rem] py-10 px-4 sm:px-10 theme-au:bg-black theme-sm:bg-white theme-au:text-white theme-asm:text-black z-[20] overflow-x-hidden overflow-y-auto"
             :class="contentCssClass">
          <button class="absolute right-4 sm:right-10 top-10 theme-au:text-white theme-sn:text-black z-10"
                  ref="closeModalButton"
                  @click="showModal = false"
                  :aria-label="closeModalText">
            <close-icon />
          </button>
          <slot></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import CloseIcon from '../../icons/close.svg';
import { nextTick, ref, watch } from 'vue';

const props = defineProps(['ariaLabelledBy', 'closeModalText', 'eventType', 'cssClass', 'contentCssClass']);
const emit = defineEmits(['open', 'close']);

const openModalButton = ref(null);
const closeModalButton = ref(null);
const showModal = ref(false);

const onKeyDown = async (e) => {
  if (e.key === "Escape") {
    showModal.value = false;
  }
}

const onOpen = async () => {
  emit('open');
  window.addEventListener('keydown', onKeyDown);
  document.querySelector('html').classList.add('overflow-hidden');
  document.querySelector('.site-content').setAttribute('inert', true);
  await nextTick();
  closeModalButton.value.focus();
}

const onClose = async () => {
  emit('close');
  window.removeEventListener('keydown', onKeyDown);
  document.querySelector('html').classList.remove('overflow-hidden');
  document.querySelector('.site-content').removeAttribute('inert');
  if (openModalButton.value) {
    openModalButton.value.focus();
  }
}

const closeModal = () => {
  showModal.value = false;
}

watch(showModal, async (newShowModal, oldShowModal) => {
  if (newShowModal) {
    await onOpen();
  } else {
    await onClose();
  }
});

document.addEventListener(
  props.eventType,
  (e) => {
    showModal.value = true;
    openModalButton.value = e.detail.buttonElement;
  },
  false
);

defineExpose({
  closeModal
})

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
