export default function () {
  const storefrontDomain = 'powersports.camso.co';
  const checkoutDomain = 'checkout.powersports.camso.co';

  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function(Didomi) {
    if (Didomi.isConsentRequired()) {
      Didomi.getObservableOnUserConsentStatusForVendor('c:shopify-stats').subscribe(function(consentStatus) {
        const consent = consentStatus === true;
        window.Shopify.customerPrivacy.setTrackingConsent(
          {
            "marketing": consent,
            "analytics": consent,
            "sale_of_data": consent,
            "preferences": consent,
            "headlessStorefront": true,
            "checkoutRootDomain": checkoutDomain,
            "storefrontRootDomain": storefrontDomain,
            "storefrontAccessToken": window.storefrontAccessToken
          }, () => console.log('consent was submitted', consent)
        );
      });
    } else {
      window.Shopify.customerPrivacy.setTrackingConsent(
        {
          "marketing": true,
          "analytics": true,
          "sale_of_data": true,
          "preferences": true,
          "headlessStorefront": true,
          "checkoutRootDomain": checkoutDomain,
          "storefrontRootDomain": storefrontDomain,
          "storefrontAccessToken": window.storefrontAccessToken
        }, () => console.log('consent was submitted', false)
      );
    }
  });
}
