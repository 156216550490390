import { capitalizeFirstLetter } from '../utils/string.js';

export default (specs, labels) => ({
  labels: labels,
  variations: specs,
  filteredVariations: specs,
  props: ["length", "width", "lug", "pitch"],
  lengthList: [],
  length: null,
  widthList: [],
  width: null,
  lugList: [],
  lug: null,
  pitchList: [],
  pitch: null,
  selectedVariation: null,
  showResults: false,
  activeVariation: null,

  init () {
    this.setProps();
  },

  setProp(propName, propValue) {
    const propIndex = this.props.indexOf(propName);
    if (this[propName] === propValue) {
      this[propName] = null;
    } else {
      this[propName] = propValue;
    }
    this.props.forEach((prop, index) => {
      if (index > propIndex) {
        this[prop] = null;
      }
    })
    this.filterVariations();
    this.setProps();
  },

  filterVariations() {
    this.filteredVariations = this.getFilteredVariations(this.props);
    this.props.forEach(prop => {
      if (this[prop]) {
        this.filteredVariations = this.filteredVariations.filter(v => v[capitalizeFirstLetter(prop)] === this[prop]);
      }
    })
  },

  setProps() {
    this.showResults = true;
    this.props.forEach((prop, index) => {
      const propName = capitalizeFirstLetter(prop);
      const list = this.getUniqueProps(propName).map(p => {
        return {
          value: p,
          available: this.getFilteredVariations(this.props.filter((p, i) => i < index)).some(v => v[propName] === p)
        }
      });
      list.forEach(p => {
        p.active = p.available && (this[prop] === p.value || list.filter(prop => prop.available).length === 1);
      })
      this[prop + "List"] = list;
      if (!list.some(p => p.active)) {
        this.showResults = false;
      }
    });

    this.activeVariation = this.showResults ? this.filteredVariations[0] : null;
  },

  getUniqueProps(propName) {
    return this.variations
      .map(v => v[propName])
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort();
  },

  getFilteredVariations(props) {
    let filteredVariations = this.variations;
    props.forEach(prop => {
      if (this[prop]) {
        filteredVariations = filteredVariations.filter(v => v[capitalizeFirstLetter(prop)] === this[prop]);
      }
    })
    return filteredVariations;
  }
});
