import ShopifyBuy from 'shopify-buy';
import { setCookie } from '../utils/cookies-utils.js';

export default function() {
  const CHECKOUT_LOCAL_STORAGE = "myCartPowersports";
  const CHECKOUT_COOKIE = "myCartPowersportsId";

  const $floatingCart = document.querySelector("#floating-cart");

  const CADollar = new Intl.NumberFormat(document.documentElement.getAttribute('lang'), {
    style: 'currency',
    currency: 'CAD',
  });

  // eslint-disable-next-line no-undef
  const client = ShopifyBuy.buildClient({
    domain: window.storefrontDomain,
    storefrontAccessToken: window.storefrontAccessToken,
  });

  const closeFloatingCart = () => {
    $floatingCart.classList.add("hidden");
  };

  const openFloatingCart = () => {
    $floatingCart.classList.remove("hidden");
  };


  const updateLocalStorage = (checkout) => {
    setCookie(CHECKOUT_COOKIE, checkout.id, 365);
    localStorage.setItem(CHECKOUT_LOCAL_STORAGE, JSON.stringify(checkout));
  };

  const updateCartItems = (checkout) => {
    const $cartItems = document.querySelectorAll("[data-cart-items]");
    $cartItems.forEach($cartItem => {
      $cartItem.innerText = checkout.lineItems.reduce((n, {quantity}) => n + quantity, 0);
    });
  };

  const updateCartSubtotal = (checkout) => {
    document.querySelectorAll('[data-cart-subtotal]').forEach($el => {
      $el.innerText = CADollar.format(checkout.subtotalPrice.amount);
    });
  };

  const getDatasetValue = (dataset) => {
    let values;
    try {
      values = JSON.parse(dataset);
    } catch(e) {
      values = [dataset];
    }
    if (!Array.isArray(values)) {
      values = [dataset];
    }
    return values;
  }

  const onCheckoutReady = async (checkout) => {
    if (!checkout) {
      checkout = await client.checkout.create();
    }

    updateLocalStorage(checkout);
    updateCartItems(checkout);

    document.querySelectorAll(".buy-button").forEach(($b) => {
      $b.addEventListener("click", () => {
        $b.classList.add("loading");
        const variants = getDatasetValue($b.dataset.defaultVariantId);
        const titles = getDatasetValue($b.dataset.productTitle);
        const productNumbers = getDatasetValue($b.dataset.productNumber);
        const prices = getDatasetValue($b.dataset.productPrice);
        let total = 0;
        const lineItems = [];
        const gaItems = [];
        variants.forEach((v, i) => {
          const quantity = parseInt($b.dataset.quantity || 1);
          const title = titles[i];
          const productNumber = productNumbers[i];
          const price = parseFloat(prices[i]);
          total += price * quantity;
          lineItems.push({
            variantId: `gid://shopify/ProductVariant/${v}`,
            quantity: quantity,
          });
          gaItems.push({
            item_id: productNumber,
            item_name: title,
            price: price,
            quantity: quantity
          });
        });
        client.checkout
          .addLineItems(checkout.id, lineItems)
          .then((c) => {
            // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
            window.dataLayer.push({
              event: "add_to_cart",
              currency: "CAD",
              value: Math.round(total * 100) / 100,
              items: gaItems
            });
            updateLocalStorage(c);
            updateCartItems(c);
            $b.classList.remove("loading");
            updateCartSubtotal(c);
            document.querySelectorAll('[data-cart-item-added]').forEach($el => {
              $el.innerHTML = titles.map(t => "<p>" + t + "</p>").join(" ");
            });
            document.querySelectorAll('[data-checkout-url]').forEach($el => {
              $el.setAttribute('href', c.webUrl);
            });
            if ($floatingCart) {
              openFloatingCart();
            }
          })
          .catch(() => {
            $b.classList.remove("loading");
          });
      });
    });

    document.querySelectorAll('[data-cart-line-item]').forEach(($p) => {
      $p.querySelector('input').addEventListener("change", () => {
        const lineItems = [];
        document.querySelectorAll('[data-cart-line-item]').forEach($i => {
          lineItems.push({
            id: $i.dataset.cartLineItem,
            quantity: parseInt($i.querySelector('input').value)
          });
        });
        client.checkout
          .updateLineItems(checkout.id, lineItems)
          .then((checkout) => {
            updateLocalStorage(checkout);
            updateCartItems(checkout);
            checkout.lineItems.forEach(l => {
              const $lineItem = document.querySelector('[data-cart-line-item="' + l.id + '"]');
              $lineItem.querySelector('[data-price]').innerText = CADollar.format(l.variant.price.amount * l.quantity);
            });
            updateCartSubtotal(checkout);
          });
      });
      $p.querySelectorAll('[data-delete]').forEach(btnDelete =>
        btnDelete.addEventListener("click", () => {
          client.checkout.removeLineItems(checkout.id, [$p.dataset.cartLineItem]).then((checkout) => {
            updateLocalStorage(checkout);
            updateCartItems(checkout);
            $p.remove();
            updateCartSubtotal(checkout);
          });
        })
      );
    });
  };

  const checkoutLocalStorage = localStorage.getItem(CHECKOUT_LOCAL_STORAGE);

  if (checkoutLocalStorage) {
    const checkout = JSON.parse(checkoutLocalStorage);
    client.checkout.fetch(checkout.id).then(onCheckoutReady);
  } else {
    client.checkout.create().then(onCheckoutReady);
  }

  document.querySelectorAll('[data-close-floating-cart]').forEach($el => {
    $el.addEventListener("click", () => {
      if ($floatingCart) {
        closeFloatingCart();
      }
    });
  });

  document.body.addEventListener('click', (e) => {
    if ($floatingCart) {
      if (e.target !== $floatingCart && !$floatingCart.contains(e.target)) {
        closeFloatingCart();
      }
    }
  });
}
