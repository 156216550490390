export default function() {
  const promoCtaButtons = document.querySelectorAll(".button-promo");
  const promoPopup = document.querySelector("#promo-popup");
  const btnClosePopup = document.querySelector("#btn-close-promo");
  const promoPopupBg =  document.querySelector("#promo-popup-bg");
  let activeCtaButton;

  if(promoCtaButtons.length <= 0 || !promoPopup) return;

  const closePromoPopup = () => {
    promoPopup.classList.add("hidden");
    activeCtaButton.focus();
  };

  const openPromoPopup = () => {
    promoPopup.classList.remove("hidden");
    btnClosePopup.focus();
  };

  const focusTrap = () => {
    const focusableElements = 'button:not(.hidden), [href]:not(.hidden), input:not(.hidden), select:not(.hidden), textarea:not(.hidden), [tabindex]:not([tabindex="-1"])';
    const focusableContent = promoPopup.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    document.addEventListener('keydown', (e) => {
      if (e.key === "Escape") {
        closePromoPopup();
      }

      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      }
    });
  }

  promoCtaButtons.forEach(button => {
    button.addEventListener("click", () => {
      activeCtaButton = button;
      openPromoPopup();
      focusTrap();
    });
  });

  btnClosePopup.addEventListener("click", () => {
    closePromoPopup();
  });

  promoPopupBg.addEventListener("click", () => {
    closePromoPopup();
  });
}
