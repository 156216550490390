import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function () {
  const staggeredCardsContainer = document.querySelector('#staggered-cards-container');
  if(!staggeredCardsContainer) return;

  let mm = gsap.matchMedia();

  mm.add("(min-width: 768px)", () => {
    gsap.fromTo('.staggered-card', {
        yPercent: 100,
        opacity: 0,
      },
      {
      yPercent: 0,
      opacity: 1,
      duration: 1.3,
      stagger: 0.2,
      ease: "sine.out",
      scrollTrigger: {
        trigger: staggeredCardsContainer,
        start: "top 70%",
        toggleActions: "play none none none",
      }
    });
  });
}
